<template>
    <div id="app">
      <div class="modal" v-if="showModal">
        <div class="modal-content">
          <h2 class="h2_text">{{ $t("Logout") }}</h2>
          <p class="p_text">{{ $t("are_u_sure_logout") }}</p>
          <div class="modal-actions" style="width: 100%;">
            <button @click="showModal = false" class="cancel">{{ $t("cancel") }}</button>
            <button @click="logoutUser" class="logout">{{ $t("Logout") }}</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  export default {
    data() {
      return {
        showModal: true
      };
    },
    methods: {
      ...mapActions(["signOut"]),
      logoutUser() {
        this.signOut();
        this.$router.push("/web/sessions/signIn");
      },
    }
  };
  </script>
  
  <style>
    .cancel {
        color: var(--Main, #FFF);
    }

    .logout {
        color: var(--Red, #D33535);
        
    }
    
    .modal-actions {
        border-top: 1px solid #393737;
        display: flex;
    }

    .modal-actions button {
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        flex: 1;
        border: none;
        padding: 10px;
        background-color: transparent;
        cursor: pointer;
        outline: none;
    }

    .modal-actions button:not(:last-child) {
        border-right: 1px solid #393737;
    }

    .p_text {
        color: var(--Secondary, #7E8088);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
    
    .h2_text {
        color: var(--Main, #FFF);
        text-align: center;
        /* Heading 4 */
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px; /* 125% */
    }

    .modal {

      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }
  
    .modal-content {

      border-radius: 14px;
      background: var(--Menu-Dark, #222223);
      backdrop-filter: blur(11px);
      padding-top: 16px;
      text-align: center;
      width: 300px;
    }

  </style>
  