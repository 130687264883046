<template>
  <!-- <b-modal id="modal-share2earn" hide-footer hide-header centered> -->
  <div class="black-dark-blue-card mx-3">
    <!-- <div class="bg-black"></div> -->
    <h2 class="h2_text text-light-green">{{ $t("activate_share2earn") }}</h2>
    <p class="p_text">{{ $t("share2earn_label") }}</p>
    <div class="p_text2 ">
      {{ $t("activate_method1") }}
    </div>
    <div class="p_text2 mb-3">
      {{ $t("activate_method2") }}
    </div>
    <div class="modal-actions" style="width: 100%;">
      <button @click="$parent.enterPin" class="text-light-green">{{ $t("activate2") }}</button>
      <button @click="$router.push('/web/upgradePackage')" class="text-light-green">{{ $t("purchase") }}</button>
    </div>
  </div>
  <!-- </b-modal> -->
</template>
  
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      showModal: true
    };
  },
  components: {
  },
  methods: {
    ...mapActions([""]),


  }
};
</script>
  
<style lang="scss">
#modal-share2earn {

  .modal-content {
    padding-top: 0px;

    .modal-body {
      height: auto;
      padding: 0px;

      .black-dark-blue-card {
        // height: 50vh;
        padding: 10px;
      }
    }

  }

  .modal-actions {
    border-top: 1px solid #3498db;
    display: flex;
  }

  .modal-actions button {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    flex: 1;
    border: none;
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
  }

  .modal-actions button:not(:last-child) {
    border-right: 1px solid #3498db;
  }

}

.p_text {
  color: #FFFFFF;
  text-align: start;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 4px;
}

.p_text2 {
  color: #FFFFFF;
  text-align: start;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.h2_text {
  color: var(--Main, #FFF);
  text-align: center;
  /* Heading 4 */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}
</style>
  