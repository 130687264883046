<template>
  <div class="main-content scrollable-element" style="
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      width: 100%;
      background-position: center;
      overflow-y: scroll;
    ">
    <div v-if="memberInfo != ''">
      <div class="position-relative text-center mx-3 mt-4 text" style="padding: 1vh">
        <h3 class="main_text text text_main_title mt-5 mx-4 ext-22 text-white">{{ $t("profile") }}</h3>
        <!-- <img src="../../../assets/images/etnex_profile/etnexlogo.png" class="pb-4" alt="" @error="imageLoadError" height="130px" /> -->

        <div class="etnex-purchase-container colorful_bg pt-0 justify-content-center align-items-center">
          <div class="purchase-content row">
            <div class="col-7">
              <div class="asset-info text-left pt-2">
                <p class="text-8 text mb-0">{{ $t("welcome_back") }}</p>
                <p class="text-20 text mb-0">{{ memberInfo.fullname }}</p>
                <p class="text-12 text mb-0"> <img src="../../../assets/images/etnex_profile/iphone.png" alt="Info"
                    class="info-icon" width="12px"> +{{ memberInfo.country.phone_code + memberInfo.contact_number.replace(/^0/, '') }}</p>
                <p class="text-10 text mb-0"> <img src="../../../assets/images/etnex_profile/email.png" alt="Info"
                    class="info-icon" width="12px"> {{ memberInfo.email }}</p>

              </div>
            </div>
            <div class="col-5 p-0">
              <img :src="getImagePath(memberInfo.rank.rank_name_en)" alt="Info" class="info-icon"
                style="width:170px;height:170px;max-width:initial">
            </div>
          </div>
        </div>

      </div>
      <div class="text mx-4">
        <div class="position-relative w-100">
          <b-card class="boxprofile mb-1 text-grey black-dark-blue-card p-0">
            <b-row no-gutters align-v="center">
              <b-col cols="6">
                <p class="mb-0 text text-left text-12">{{ $t("walletaddress") }}</p>
              </b-col>
              <b-col cols="6" class="mb-0 text-right text-white text-10">
                {{ memberInfo.username.length > 9 ? memberInfo.username.substring(0, 8) + '.....' +
                  memberInfo.username.slice(-8) : memberInfo.username }}
              </b-col>
              <b-col cols="12">
                <hr class="mt-2 mb-3" />
              </b-col>
              <b-col cols="6">
                <p class="mb-0 text text-left text-12">ID</p>
              </b-col>
              <b-col cols="6" class="mb-0 text-right text-white text-10">
                {{ userID }}
              </b-col>
              <b-col cols="12">
                <hr class="mt-2 mb-3" />
              </b-col>
              <b-col cols="6">
                <p class="mb-0 text text-left text-12">{{ $t("rank") }}</p>
              </b-col>
              <b-col cols="6" class="mb-0 text-right text-white text-10">
                {{ $i18n.locale == "zh" ? memberInfo.rank.rank_name : memberInfo.rank.rank_name_en }}
              </b-col>
              <b-col cols="12">
                <hr class="mt-2 mb-3" />
              </b-col>
              <b-col cols="6">
                <p class="mb-0 text text-left text-12">{{ $t("register_date") }}</p>
              </b-col>
              <b-col cols="6" class="text-right text-white text-10">
                {{ memberInfo.created_at }}
              </b-col>
              <b-col cols="12">
                <hr class="mt-2 mb-3" />
              </b-col>

            </b-row>
          </b-card>
        </div>
        <h4 class="text-white font-weight-bold mt-4 mb-2 text-light-green">{{ $t("general_information") }}</h4>
        <div class="position-relative w-100">
          <b-card class="boxprofile text-white mb-1 px-0 py-0 black-dark-blue-card">
            <!-- <h5 class="font-weight-bold mt-1 mb-2 text-light-green">{{ $t("kyc") }}</h5>
            <div v-if="isNonClickable">
              <ProfileBox :info="profileInfo" />
            </div>

            <b-link v-else :to="kycLink">
              <ProfileBox :info="profileInfo" />
            </b-link>
            <hr class="mt-0 mb-3" /> -->
            <div class="block-box">
              <div class="activate" v-if="active_ref == 0">
                <!-- <b-button variant="primary" class="btn-curved-profile colorful_bg w-50" @click="showshareModal">
                  {{ $t("activate2") }}
                </b-button> -->
                <Share2earn></Share2earn>

              </div>
              <h5 class="font-weight-bold mt-1 mb-2 text-light-green ">{{ $t("ref_id") }}</h5>
              <b-row align-h="center" class="">
                <b-col md="8 my-1 mb-1">
                  <div class="form-group row">
                    <div class="gradient-box p-4 mx-auto mb-0 rounded">
                      <div class="qr-bg">
                        <qrcode :size="size" :cls="qrCls" :value="active_ref == 0?'':inv_link"></qrcode>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row no-gutters align-v="center">
                <b-col cols="6">
                  <p class="mb-3 text-left text-14">{{ $t("ref_link") }}</p>
                </b-col>
                <b-col cols="6" class="mb-3 text-right text text-12">
                  {{ inv_link.length > 19 ? inv_link.substring(0, 12) + '...' + inv_link.slice(-4) : inv_link }}
                  <b-link class="fa fa-copy" v-if="active_ref != 0" v-clipboard="() => inv_link" v-clipboard:success="clipboardSuccessHandler"
                    v-clipboard:error="clipboardErrorHandler"></b-link>
                </b-col>

                <b-col cols="6">
                  <p class="mb-3 text-left text-14">{{ $t("ref_id") }}</p>
                </b-col>
                <b-col cols="6" class="mb-3 text-right text text-12">
                  {{ active_ref == 0?'':memberInfo.ref_code }}
                </b-col>

                <b-col cols="6">
                  <p class="mb-3 text-left text-14">{{ $t("username") }}</p>
                </b-col>
                <b-col cols="6" class="mb-3 text-right text text-12">
                  {{ memberInfo.fullname }}
                </b-col>
              </b-row>

              <b-row no-gutters class="mx-2 mb-1 pb-3">
                <b-col cols="12" class="">
                  <b-button type="button" class="my-1 btn-curved-profile colorful_bg" @click="shareBtn">
                    <div class="text-black font-weight-bold">
                      {{ $t("share_ref") }}
                      <!-- <img :src="share_icon" class="ml-2"/> -->
                    </div>
                  </b-button>
                </b-col>
              </b-row>

            </div>
            <hr class="mt-0 mb-2" />
            <h5 class="font-weight-bold mt-2 mb-1 text-light-green">{{ $t("my_wallet") }}</h5>

            <b-link to="/web/deposit/deposit">
              <ProfileBox class="text-5" info="deposit"></ProfileBox>
            </b-link>

            <!-- <b-link to="/web/wallet/record">
              <ProfileBox class="text-5" info="wallet_record"></ProfileBox>
            </b-link> -->

            <b-link to="/web/withdraw/withdraw">
              <ProfileBox class="text-5" info="withdraw"></ProfileBox>
            </b-link>

            <hr class="mt-0 mb-2" />
            <h5 class="font-weight-bold mt-2 mb-1 text-light-green">{{ $t("tree") }}</h5>

            <b-link to="/web/tree">
              <ProfileBox class="text-5" info="tree"></ProfileBox>
            </b-link>

            <hr class="mt-0 mb-2" />
            <h5 class="font-weight-bold mt-2 mb-1 text-light-green">{{ $t("bonus_record") }}</h5>

            <b-link to="/web/bonus/bonusRecord">
              <ProfileBox class="text-5" info="bonus"></ProfileBox>
            </b-link>

            <b-link to="/web/bonus/share2EarnRecord">
              <ProfileBox class="text-5" info="share2earn_bonus"></ProfileBox>
            </b-link>

            <hr class="mt-0 mb-2" />
            <h5 class="font-weight-bold mt-2 mb-1 text-light-green">{{ $t("security_center") }}</h5>
            <b-link to="/web/me/password?type=sec_pwd">
              <ProfileBox info="reset_sec_password"></ProfileBox>
            </b-link>

            <hr class="mt-0 mb-2" />
            <h5 class="font-weight-bold mt-2 mb-1 text-light-green">{{ $t("misc") }}</h5>

            <b-link>
              <ProfileBox info="introduction_etnex"></ProfileBox>
            </b-link>
            <b-link>

              <ProfileBox info="faq"></ProfileBox>
            </b-link>
            <hr class="mt-0 mb-2" />
            <b-link class="text-center mt-3">
              <h5 @click="toggleLogoutPopup" class="mb-0 text-logout text-16 font-weight-bold mt-4">{{ $t("sign_out") }}
              </h5>
              <PopupLogout v-if="showLogoutPopup" @close="showLogoutPopup = false" />
            </b-link>
          </b-card>
        </div>
      </div>
      <div class="position-relative text-center mb-4" style="padding: 1vh">
        <p class="mt-3 mb-0 text-white text-10">
          {{ $t("tnc") }} | {{ $t("privacy_policy") }}
        </p>
        <p class="mb-4 mt-0 text-grey text-10">
          &copy; {{ $t("etnex_allrightreserved") }}
        </p>
      </div>
    </div>

    <div style="height: 60px"></div>

    <b-modal id="modal-logout" size="md" centered :title="$t('sign_out')" :hide-footer="true"
      style="background-color: #5f646e !important" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <b-form class="mx-5" @submit.prevent="logoutUser">
        <b-row align-h="center">
          <b-col md="12 mb-30">
            <b-row align-h="center" style="margin-bottom: 10px">
              <div class="form-group">
                <div class="col-sm-12">
                  <div class="mt-4 float-left">
                    <b-button type="submit" class="m-1" variant="outline-secondary" style="
                        background: linear-gradient(
                          to bottom,
                          #febec1,
                          #d329e6
                        ) !important;
                      ">{{ $t("sign_out") }}</b-button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <div class="mt-4 float-right">
                    <b-button class="m-1" variant="outline-secondary"
                      style="border: 2px solid #febec1; border-radius: 10px" @click="close()">{{ $t("cancel")
                      }}</b-button>
                  </div>
                </div>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal id="modal-pending" size="md" centered :hide-footer="true" style="background-color: #5f646e !important">
      <b-form class="mx-5">
        <b-row align-h="center">
          <b-col md="12 mb-30">
            <b-row align-h="center" style="margin-bottom: 10px">
              <!-- <div class="form-group">
                   <i class="fa fa-clock-o" style="font-size:100px;color:purple"></i>
              </div> -->
              <div class="spinner spinner-primary m-2 text-50"></div>
              <div class="col-sm-12">
                <center>
                  <b-button class="mx-auto submit_button mt-5" variant="outline-secondary" @click="close()">{{ $t("close")
                  }}</b-button>
                </center>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>



    <Dialog ref="msg"></Dialog>

    <enter-pin ref="enterPin" @submit-pin="LoadActive_Ref"></enter-pin>
  </div>
</template>

<script>
import EnterPin from '../pin/enter_pin.vue';
import Share2earn from '../popup/share2earn.vue';
import PopupLogout from '../popup/profilelogout.vue';
import { getMemberInfo, activeRef} from "../../../system/api/api";
import Qrcode from "v-qrcode/src/index";
import { handleError } from "../../../system/handleRes";
import Dialog from "../../../components/dialog.vue";
import ProfileBox from "./profileBox.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["lang"]),
    isNonClickable() {
      return this.check_kyc.can_withdraw === 'approved' || this.check_kyc.can_withdraw === 'pending';
    },
    profileInfo() {
      if (this.check_kyc.can_withdraw === 'failed' && typeof this.check_kyc.failed_reason === 'undefined') {
          return 'kyc';
      } else {
          return this.check_kyc.can_withdraw;
      }

    },
    kycLink() {
      return '/web/me/kyc';
    },
  },
  components: {
    Dialog,
    ProfileBox,
    Qrcode,
    PopupLogout,
    Share2earn,
    EnterPin

  },
  data() {
    return {
      qrCls: "qrcode",
      size: 180,
      inv_link: "",

      otp: "",
      email: "",
      isLoading: false,
      editing: false,
      userID: 0,
      total_sponsor: "",
      point1: 0,
      point2: 0,
      ref_code: "",
      approval: "",
      dataList: [],
      notic: [],
      show: false,
      showModal: false,
      bind: true,
      memberInfo: "",
      rank: null,
      active_ref: null,
      country: null,
      phone: null,
      phone_code: null,
      countryOptions: [],
      countryList: [],
      share_icon: require("../../../assets/images/etnex_profile/share_icon.png"),
      userIcon: require("../../../assets/images/faces/user.png"),
      supportEN: localStorage.getItem("SUPPORT_URL_EN"),
      // supportCN: 'https://ibo.greatwallsolution.com'+localStorage.getItem('SUPPORT_QR_CN')
      supportCN:
        location.origin +
        "/upload" +
        localStorage.getItem("SUPPORT_QR_CN") +
        ".jpg",

      showLogoutPopup: false,
      check_kyc: '',
    };
  },
  watch: {},
  props: ["success"],
  methods: {

    enterPin() {
      this.$refs.enterPin.open();
    },
    getImagePath(filename) {
      return require(`@/assets/images/etnex_profile/${filename}.png`);
    },
    ...mapActions(["signOut", "changeLan"]),
    imageLoadError(event) {
      event.target.src = this.userIcon;
    },
    shareBtn() {
      let shareData = {
        title: "Etnex",
        text: "Let's join Etnex!",
        url: this.inv_link,
      };
      navigator.share(shareData);
    },
    goInfo() {
      this.$router.push({
        name: "basic_info",
        params: {
          item: this.memberInfo,
        },
      });
    },
    returnIcon() {
      if (this.rank != null) {
        // return location.origin + this.rank.icon;
        return (
          "https://ibo.greatwallsolution.com" + this.rank.icon.toLowerCase()
        );
      }
      return "";
    },

    openOtp() {
      this.$bvModal.show("modal-logout");
    },
    close() {
      this.$bvModal.hide("modal-logout");
    },

    logoutUser() {
      this.signOut();

      this.$router.push("/web/sessions/signIn");
    },
    clipboardSuccessHandler() {
      this.$refs.msg.makeToast("success", this.$t("copied"));
    },
    clipboardErrorHandler() {
      //// console.log('error', value)
    },
    loadItems() {
      var result = getMemberInfo();

      var self = this;
      this.isLoading = true;
      self.inv_link = location.origin + "/web/sessions/signIn?ref_id=";
      result
        .then(function (value) {
          self.editing = false;
          self.memberInfo = value.data.data;
          self.ref_code = value.data.data.ref_code;
          self.inv_link = self.inv_link + self.ref_code;
          self.userID = value.data.data.id;
          self.total_sponsor = value.data.total_sponsor;
          self.point1 = parseFloat(value.data.data.point1).toFixed(0);
          self.point2 = parseFloat(value.data.data.point2).toFixed(0);
          self.rank = value.data.data.rank;
          self.phone = value.data.data.contact_number;
          self.phone_code = value.data.data.country.phone_code;
          self.country = value.data.data.country.id;
          self.active_ref = value.data.data.active_ref;
          self.check_kyc = value.data.data.check_kyc;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },
    LoadActive_Ref(pin) {
      let formData = new FormData();
      var self = this;
      this.isLoading = true;
      formData.append("sec_password", pin);
      var result = activeRef(formData);
      result
        .then(function (value) {

          if (value.data.error_code == '0') {
            self.$refs.msg.makeToast("success", self.$t(value.data.message));
            self.loadItems();
          } else {
            self.$refs.msg.makeToast("danger", self.$t(value.data.message));
          }
          // self.active_act = value.data.data;
        })
    },
    toggleLogoutPopup() {
      this.showLogoutPopup = !this.showLogoutPopup;
    },
    showshareModal() {
      if (this.active_ref == 0) {
        this.$bvModal.show("modal-share2earn");

      }
    },
  },
  mounted() {
    this.loadItems();
    // this.LoadActive_Ref();
    this.email = localStorage.getItem("username");
  },
};
</script>

<style>
.modal-title {
  color: black;
}

.modal-body {
  height: 50vh;
}

.imgBox {
  background: rgb(113, 33, 179);
  border-radius: 10px;
  padding: 4px;
  height: 40px;
  width: 40px;
  position: relative;
}

.imgBox img {
  padding: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-box {
  background-color: white;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 8px 10px;
  color: #000;
}

.label-box {
  background: rgb(201, 135, 243);
  border-radius: 5px;
  width: 100%;
  color: white;
}

.wallet-box {
  background: white;
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
  width: 90%;
  box-shadow: 0px 5px 6px rgb(201, 135, 243);
}

.hiddenClass {
  pointer-events: none;
  display: none;
}

.overlay-text {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  color: #000;
  font-weight: 700;
  line-height: 1.5;
}

.form-custom-append {
  height: calc(2.5rem + 2px);
}

.form-custom-prepend {
  background-color: transparent !important;
  height: calc(2.5rem + 2px) !important;
}

h5#modal-logout___BV_modal_title_ {
  margin: auto;
}

button.close {
  display: none;
}

.banner-image2 {
  background-image: url("../../../assets/images/purple_banner.png");
  background-size: 100% 100%;
  height: 30vh;
  background-repeat: no-repeat;
  padding-top: 25px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

i.fa.fa-exclamation-circle {
  color: red;
  font-size: 25px;
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 25px;
  background: #000;
}

.boxprofile {
  background: linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%);
  /* background: linear-gradient(45deg, rgba(237, 237, 237, 0.2), rgba(169, 169, 169, 0.2)); */
}

.btn-curved-profile {
  color: #251740;
  padding: 12px 10px 12px 10px;
  width: 100%;
  border-radius: 12px;
  background-position: center;
  border: 1px solid #3BEDEA !important;
  font-weight: 600;
  font-size: 16px;
}

.qr-bg {
  background: white;
  padding: 20px 0px 0px 14px;
}

.header {
  display: flex;
  /* padding: 0px 16px; */
  margin-top: 4rem !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.text {
  color: var(--Main, #FFF);
  font-family: Poppins;
  font-style: normal;
}

.main_text {
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 7px;
}

.text_main_title {
  flex-grow: 1;
  text-align: center;
}

.mt-3,
.my-3 {
  margin-top: 3px !important;
}

.mb-1,
.my-1 {
  margin-bottom: 1rem !important;
}

.purchase-content h2 {
  margin-bottom: 16px;
}
</style>