<template>
  <div class="d-flex align-items-center mb-3">
      <!--<div class="text-center text-24" style="margin-right: 10px; width: 40px">
        <img height="24px" :src="img" alt="" />
      </div> -->

      <h6 class="mb-0 mt-3 text-14 text-white  flex-grow-1">
        {{ $t(info) }}
      </h6>
      <i class="fa fa-chevron-right"></i>
    </div>
</template>

<script>
export default {
  props: ["info"],
  data() {
    return {
      img: require("../../../assets/images/profile/kyc.svg"),
    };
  },
  created() {
    // this.img = require("../../../assets/images/latrader_profile/" + this.info + ".png");
  },
};
</script>